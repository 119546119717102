import { apolloClient } from './apollo';
import * as internalAdmin from '@/models/internalAdmin';
import store from '../store';

async function statistics(field, viewScope = 'day') {
  const x = await apolloClient.query({
    query: internalAdmin.statistics(field),
    variables: {
      calculationPolygonId: store.getters.getPolygonId,
      viewScope,
      lastCountViewScope: 14
    },
  })
  .then((data, loading) => {
    if (!loading) {
      return data.data.statistics[field];
    }
  })
  .catch((error) => {
    return error;
  });

  return x;
}

export default statistics;
