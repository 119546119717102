<template>
  <div class="main">
    <div class="charts-wrap"
         v-if="!groupId">
      <statistics-bar-chart v-for="(el, index) in statisticsCharts"
                            v-bind:key="index"
                            v-bind:dataLabel="el.title"
                            v-bind:code="el.code"
                            v-bind:viewScope="el.viewScope"
                            v-bind:dataLabels="el.dataLabels"
                            ref="statChart"
                            v-bind:seriesName="el.seriesName"></statistics-bar-chart>
    </div>
    <div v-else class="no-data">Данных не найдено</div>
  </div>
</template>

<script>
import StatisticsBarChart from '@/components/chart/StatisticsBarChart.vue';
import * as constants from '@/assets/data/constants.js';

export default {
  components: {
    StatisticsBarChart
  },
  computed: {
    polygonId() {
      return this.$store.getters.getPolygonId;
    },
    groupId() {
      return this.$store.getters.getGroupId;
    },
    statisticsCharts() {
      return constants.statisticsCharts;
    }
  },
  watch: {
    polygonId(val, prev) {
      this.$refs.statChart.forEach(el => el.update());
    }
  }
}
</script>

<style lang="scss" scoped>
.charts-wrap {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3rem;
}

.no-data {
  max-width: 80rem;
  margin: 4rem auto 0;
  background-color: $gray-100;
  text-align: center;
  padding: 2rem 1.5rem;
  font-size: 1.6rem;
  border-radius: $border-radius;
}

@media only screen and (max-width: 1820px) {
  .charts-wrap {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1270px) {
  .charts-wrap {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 900px) {
  .charts-wrap {
    grid-template-columns: 1fr;
  }
}
</style>