<template>
  <div class="chart-wrap">
    <data-loader v-bind:isLoading="isLoading"
                 innerText="Подождите, данные загружаются"
                 v-bind:isSpinner="true" />
    <vue-apex-charts v-bind:options="chartOptions"
                     v-bind:series="series"
                     height="320"
                     v-if="!isLoading"></vue-apex-charts>
    <div class="apex-chart-legend"
         v-if="dataLabels">
      Среднее изменение за последние {{ lastNPeriodChangeArrLengthWithDescription }}: {{ lastNPeriodChangeAverageVal }}
    </div>
  </div>
</template>

<script>
import VueApexCharts  from 'vue-apexcharts';
import statistics from '@/lib/statistics';
import DataLoader from '@/components/DataLoader.vue';
import moment from 'moment';

export default {
  props: {
    code: [String, Number],
    dataLabel: String,
    seriesName: String,
    viewScope: String,
    dataLabels: Boolean
  },
  components: {
    VueApexCharts,
    DataLoader
  },
  data() {
    return {
      isLoading: true,
      seriesData: [],
      xAxisCategories: []
    }
  },
  computed: {
    polygonId() {
      return this.$store.getters.getPolygonId;
    },
    series() {
      return [{
        name: this.seriesName,
        data: this.seriesData
      }]
    },
    lastNPeriodChangeArr() {
      const arr = this.seriesData.map((el, index) => {
        const prev = this.seriesData[index - 1];
        const seriesLength = this.seriesData.length;

        if (index > seriesLength - 8 && index != seriesLength - 1) {
          if (prev == 0 && el != 0) return 100;
          return el;
        }

        return;
      });

      return arr.filter(elInner => elInner != null && !isNaN(elInner));
    },
    lastNPeriodChangeArrLengthWithDescription() {
      const l = this.lastNPeriodChangeArr.length;

      return `${l} ${this.declineWord(l, ['период', 'периода', 'периодов'])}`
    },
    lastNPeriodChangeAverageVal() {
      return `${Math.round((Math.pow((this.lastNPeriodChangeArr[this.lastNPeriodChangeArr.length - 1] / this.lastNPeriodChangeArr[0]), 1 / 6) - 1) * 100)}%`;
    },
    chartOptions() {
      return {
        colors: ['#26c435'],
        title: {
          text: this.dataLabel,
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
            fontFamilt: 'Lato'
          }
        },
        chart: {
          id: this.code,
          group: 'statistic',
          type: 'bar',
          height: 320,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 4,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: this.dataLabels,
          formatter: (value, timestamp) => {
            const dataPointIndex = timestamp.dataPointIndex;
            const prev = this.seriesData[dataPointIndex - 1];
            const v = Math.round((value - prev) / prev * 100);
            const sign = v > 0 ? '+' : '-';

            if (dataPointIndex == this.seriesData.length - 1 || (prev == 0 && value == 0) || dataPointIndex == 0) return '';

            if (prev == 0 && value != 0) return '+100%';
            
            return `${sign}${Math.abs(v)}%`;
          },
          offsetY: -20,
          style: {
            fontSize: '10px',
            colors: ["#304758"]
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.xAxisCategories,
        },
        yaxis: {
          labels: {
            minWidth: 40
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            }
          }
        },
      }
    },
  },
  methods: {
    declineWord(number, words) {  
      return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
    },
    loadChart(viewScope) {
      statistics(this.code, viewScope).then(res => {
        if (res) {
          this.isLoading = false;
          this.seriesData = Array.from(res, x => x.value);
          
          res.forEach(el => {
            let date = new Date(el.year, el.month - 1, el.day, 0, 0, 0, 0);
            let momentFormat;
            let momentFormatDefault = 'DD.MM.YYYY';

            switch(this.viewScope) {
              case 'day':
                momentFormat = momentFormatDefault;
                break;

              case 'month':
                momentFormat = 'MM.YYYY';
                break;

              default:
                momentFormat = momentFormatDefault;
                break;
            }
            
            const utcTime = moment.utc(date);
            this.xAxisCategories.push(moment(utcTime).local().format(momentFormat));
          });
        }
      });
    },
    update() {
      this.isLoading = true;
      this.seriesData = [];
      this.xAxisCategories = [];

      this.loadChart(this.viewScope);
    }
  },
  mounted() {
    this.loadChart(this.viewScope);
  }
};
</script>

<style lang="scss" scoped>
.apexcharts-title-text {
  font-weight: 400;
  font-family: $font-family-sans-serif;
}

.chart-wrap {
  position: relative;
  min-height: 35rem;
}

.apex-chart-legend {
  font-size: 1.2rem;
}

.legend-val {
  font-size: 1rem;
  font-weight: 700;
}

@media only screen and (max-width: 1320px) {
  .chart-wrap {
    min-height: 28rem;
  }
}

@include media-breakpoint-down(sm) {
  .chart-wrap {
    min-height: 24rem;
  }
}
</style>
